/*
 *
 * Mse003 css
 * Author: Locnt
 */
.mse003 {
  .wj-flexgrid {
    width: 100% !important;
    max-height: calc(100vh - 355px);
  }
  // .wj-control > div {
  //   max-height: calc(100vh - 355px) !important;
  // }
}
