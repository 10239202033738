/*
 *
 * Sea001 css
 * Author: LanVt
 */
.sea001 .wj-flexgrid {
  width: 100% !important;
  max-height: 390px;
}
.sea001 .table-fix {
}
