.sp {
  width: 32px;
  height: 32px;
  clear: both;
  margin: 20px auto;
}
.sp.sp-slices {
  border-radius: 50%;
  border-top: 16px rgba(0, 0, 0, 0.75) solid;
  border-left: 16px rgba(0, 0, 0, 0.25) solid;
  border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
  border-right: 16px rgba(0, 0, 0, 0.25) solid;
  -webkit-animation: spSlices 1s infinite linear;
  animation: spSlices 1s infinite linear;
}

.overlay-loading {
  position: fixed;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  margin: -30px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
  z-index: 999;
}

@mixin spSlices {
  0% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  25% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.75) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  50% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.75) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  75% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.75) solid;
  }
  100% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
}

@-webkit-keyframes spSlices {
  @include spSlices;
}

@keyframes spSlices {
  @include spSlices;
}
