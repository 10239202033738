// ---- css custom style -----
body {
  min-width: 1200px;
  background-color: #fcf2e3;
}
.mt-15 {
  margin-top: 15px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-52 {
  margin-top: 52px !important;
}
.pg-l-30 {
  padding-left: 30px;
}
.txt-align-left {
  text-align: left !important;
}
.txt-align-right {
  text-align: right !important;
}
.flex {
  display: flex;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-40 {
  width: 40%;
}
.w-15 {
  width: 15%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-67 {
  width: 67%;
}
.w-100-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
.main {
  /* margin-top: 66px; */
  /* padding-top: 36px; */
  overflow-x: unset;
}
.top-bar {
  left: 0;
}
.row-group-flex {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  margin-bottom: 5px;
}
.lbl {
  width: 150px;
  flex: 0 0 150px;
}
.lbl span:not(.required) {
  color: #4065ac;
}

.group-lbl-input {
  justify-content: flex-start;
}
.bd-none {
  border: none;
}
.align-item-center-flex {
  align-items: center;
}
.d-f {
  align-items: center;
}
.user_master_btn_wrap button {
  margin-left: 0px;
  margin-right: 16px;
}

.container-not-online {
  display: flex;
  text-align: center;
}

a[href^="https://www.grapecity.com/licensing/wijmo"],
a[href^="https://www.grapecity.co.jp/developer/support/wijmo-license"] {
  display: none;
}
.display-none {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}
.lbl-btn-upload-file {
  background-color: #ccc;
  padding: 2px 4px;
  border: 2px solid #a8a8a8;
  border-radius: 4px;
  padding: 1px 8px;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  width: auto !important;
  color: #000 !important;
  cursor: pointer;
  margin-left: 10px;
}
.white-space-nowrap {
  white-space: nowrap;
}
.flex-start {
  align-items: flex-start;
}
.text-align-right {
  text-align: right !important;
}
.text-align-center {
  text-align: center !important;
}
.w-50 {
  width: 50%;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input {
  &.autofill-transparent {
    &:-webkit-autofill,
    &-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    }
  }
}

.flex-grow {
  flex-grow: 1;
  display: flex;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flex {
  display: flex;
}
.justify-content-end {
  justify-content: flex-end;
}
.li-checkbox-all {
  min-width: 100% !important;
}
.li-checkbox-all span {
  color: initial !important;
}
.position-relative {
  position: relative !important;
}
.color-dark-moderate-blue {
  color: #4065ac;
}
.input-file-rd-with-border {
  border: 2px solid #a8a8a8 !important;
  background-color: #fff !important;
  background: #fff !important;
}

// css for object :read-only
input:read-only,
select:disabled {
  background-color: none;
  border: none;
}

textarea:read-only:hover {
  cursor: not-allowed;
}
[aria-disabled='true'],
[disabled] {
  cursor: not-allowed;
}

.unset-flex {
  flex: unset;
}
.color-error {
  color: #f44336;
}
.rd {
  border: transparent;
  cursor: not-allowed;
}
