/*
 *
 * Select css
 * Author: LanVt
 */
$select-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+';
.select_wrap::before {
  z-index: -2;
  border: none !important;
  width: 0px !important;
  height: 0px !important;
}
.select_wrap select {
  background-image: url(#{$select-arrow});
  background-position: right 0 top 50%; // better way to background-position
  background-repeat: no-repeat;
  background-size: auto 100%;
}
