/*
 *
 * Sea001 css
 * Author: LanVt
 */
.sea002 .wj-flexgrid {
  width: 100% !important;
  max-height: 326px;
}
.sea002 .table-fix {
  /* height: calc(100% - 220px) !important; */
}
