/*
 *
 * Table css
 * Author: LanVt
 */
.wj-flexgrid {
  max-height: 200px;
  width: auto !important;
  max-width: 100% !important;
  /* max-height: 90%; */
}
.wj-cell.wj-header {
  background-color: #333 !important;
  color: #fdfdfd !important;
  font-weight: 500 !important;
}
/* .table-fix {
  height: calc(100% - 122px);
} */
.wj-rowheaders .wj-cell,
.wj-topleft .wj-cell,
.wj-bottomleft .wj-cell {
  background-color: #ccc !important;
}
.wj-colfooters .wj-cell {
  background-color: #fff799 !important;
  color: initial !important;
  border-top: 2px solid #acacac;
}
.wj-cell.wj-alt {
  background-color: #fff !important;
}

.wj-cell {
  border-right: solid 1px #ddd !important;
  border-bottom: solid 1px #ddd !important;
}
.wj-cell.bg-gray,
.wj-cell.wj-alt.bg-gray {
  background: #ccc !important;
}
.wj-cells .wj-cell.wj-state-multi-selected,
.wj-cells .wj-cell.wj-alt.wj-state-multi-selected {
  background: #4065ac !important;
  color: #fff;
}
.wj-cells .wj-cell.wj-state-multi-selected .text-link {
  color: #fff;
}
.wj-columnfiltereditor input[type='checkbox'] {
  display: initial;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
  height: auto;
  width: auto;
}
.wj-columnfiltereditor input[type='checkbox' i] {
  background-color: initial;
  cursor: default;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}
.wj-glyph-filter {
  top: -5px !important;
}

.format-right {
  text-align: right;
}

.format-left {
  text-align: left;
}

/* .wj-rowheaders .wj-cell,
.wj-topleft .wj-cell,
.wj-bottomleft .wj-cell {
  width: 32px !important;
  height: 32px !important;
} */
/* div[wj-part='tl'],
div[wj-part='bl'] {
  width: 32px !important;
}
div[wj-part='cf'],
div[wj-part='cells'],
div[wj-part='ch'] {
  left: 32px !important;
} */
