/*
 *
 * Sea001 css
 * Author: LanVt
 */

.search-popup .top-bar {
  height: auto;
}
.search-popup .main {
  padding-top: 0;
}
