.submenu {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -4px;
  margin-top: 4px;
}

.btn-hide {
  display: none;
}

.submenu li {
  padding: 5px 0;
  /* background: #b5b1b1; */
  margin: 0 !important;
}

.submenu li:hover {
  background: #bdbdbd;
}

.list-btn {
  justify-content: flex-end;
}

.list-btn button:first-child {
  margin-right: 10px;
}

.submenu {
  text-align: left;
  font-weight: normal;
  font-family: 'ＭＳ Ｐゴシック';
  background-color: #ececec;
}

.submenu li {
  border-bottom: 1px solid #999;
}

.submenu li span {
  padding: 5px 10px;
}

.submenu li:first-child {
  border-top: 1px solid #999;
}

.button-sub-menu {
  height: auto;
  min-height: 30px;
}

.submenu li:last-child {
  border-bottom: none;
}

.btnDisable {
  padding: 4px 8px;
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
  cursor: no-drop;
}

.btn-mse001 {
  margin-top: 5px;
  width: 36px;
  background: #cccccc;
  display: flex;
  justify-content: center;
}

.btn-mse001 span {
  width: 36px;
  background: #cccccc;
}

.button-loading {
  width: 20px;
}

button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
