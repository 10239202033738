/*
 *
 * Input css
 * Author: LanVt
 */
.input-warp {
  position: relative;
}
.input-error {
  border-color: #a94442 !important;
  /* background-color: #f2dede; */
}
.input-warning {
  border-color: #ff9800 !important;
  /* background-color: #f2dede; */
}
.tooltip-readonly {
  border: 2px solid rgba(64, 101, 171, 0.5) !important;
  background-color: rgba(64, 101, 171, 0.1) !important;
}
.tooltip-readonly.input-error {
  border-color: #a94442 !important;
}
.tooltip {
  border: 2px solid #f1b606;
  z-index: 200;
  box-shadow: 0 1px 1px #ccc;
  min-width: 160px;
  width: auto;
  padding: 2px;
  position: absolute;
  left: 50%;
  margin-left: 0;
  background-color: #ffc107;
  border-radius: 5px;
  color: #000;
  top: -34px;
  content: attr(aria-label);
  text-transform: none;
  opacity: 0;
  font-size: 14px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  white-space: nowrap;
  visibility: hidden;
}
.tooltip:after {
  opacity: 0;
  content: '';
  position: absolute;
  border: 6px solid #ffc107;
  border-color: #ffc107 transparent transparent;
  width: 0;
  height: 0;
  bottom: -12px;
  left: 5%;
  margin-left: -6px;
  transition: all 0.5s ease;
  visibility: hidden;
}
.tooltip.tooltip-focus,
.tooltip.tooltip-focus:after {
  opacity: 1;
  visibility: visible;
}

.input-format:not(:focus) {
  color: transparent !important;
}
.input-format {
}
.color-transparent {
  color: transparent !important;
}
.is-format {
  text-align: left !important;
  margin-left: 8px;
}
.show-format-num span {
  transition: color 2s;
  color: rgb(0, 0, 0);
}
.show-format-num span.span-disabled {
  color: rgb(84, 84, 84);
}
