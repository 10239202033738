/*
 *
 * Label css
 * Author: LanVt
 */

.lbl-unrequited {
  color: inherit !important;
}
.lbl-note {
  width: auto !important;
  padding: 1px 8px;
}
